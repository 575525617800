import { computed } from '@nuxtjs/composition-api';

import { useScreenSize } from '~/diptyqueTheme/composable';
import type { Visual } from '~/diptyqueTheme/types/contentful';

export const useVisual = (visualData: Visual) => {
  const { isDesktop } = useScreenSize();

  const ratio = computed(() => (isDesktop.value && visualData?.ratioD ? visualData.ratioD : visualData?.ratio));
  const visual = computed(() => (isDesktop.value && visualData?.visD ? visualData.visD : visualData?.vis));
  const position = computed(() => (isDesktop.value && visualData?.posD ? visualData.posD : visualData?.pos));

  return {
    ratio,
    visual,
    position
  };
};
