export const getButtonsWithSkuLinks = (sectionData, sku) => {
  const items = sectionData?.buttonCollection?.items?.filter((i) => i) || [];
  if (!sku) return items;

  return items.map((item) => {
    if (!item.link) {
      item.link = '#addToCart:' + sku;
    }

    return item;
  });
};
